import React from 'react'
import "./mystyles.scss"
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/SEO'

export default function judy() {
    return (
        <Layout>
            <SEO />
            <section className="container pb-5">
                <div className="pb-5">
                    <p className="title has-text-primary pl-5">
                        Judy McHugh
                    </p>
                    <div className="columns pb-5">
                        <div className="column is-3">
                            <StaticImage 
                                src="../images/judy-blue-circle.png"
                                alt="Judy McHugh"
                                placeholder="blurred"
                            />
                        </div>
                        <div className="column is-9">
                            <p className=" subtitle has-text-primary p-6 has-text-weight-bold is-4">Supporting you and your team members' in a journey toward personal and professional growth is my passion and strength. My mission is to guide each person toward utilizing a positive mindset, increasing their energy level and living a life of greater productivity, success and happiness. I love that my coaching and leadership development consulting business allows me to do just that.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container has-text-light pb-5">
                <p className="pb-5">I began my career with a performance consultancy whose core capabilities included designing and delivering in-person and virtual workshops that were strengthened through technology-based business simulations. It was an exciting experience that confirmed my career choice of helping others develop their business and leadership skills. I have spent my entire adult life in the learning and development field and love it to this day.</p>
                <p className="pb-5">It is so fulfilling to partner with individuals, teams and organizations by supporting their leadership journey. Through coaching, I have the pleasure of encouraging my clients’ to shift their energy and engagement levels to new heights. I have seen many clients reach goals and dreams that they never thought were possible.</p>
                <p className="pb-5">The leadership workshops I customize and deliver for groups and teams include topics such as Positive Intelligence and Mental Fitness, influencing others, dynamic communications, the seven levels of leadership, and high energy relationships, among others. This work is very rewarding as it aligns leaders with their organization’s vision, mission and strategy as well as enhancing individual growth and performance.</p>
                <p className="pb-5">Working together with Human Resources and other senior leaders to create talent and performance processes is another important area because it focuses on helping people achieve their potential. Building careers and developing organizational bench strength is critical in engaging team members and retaining top talent. I feel fortunate to have the opportunity to partner with leaders and organizations in the realization of their leadership vision.</p>
            </section>
        </Layout>
    )
}
